import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Straights from "./components/Straights";
import WeAre from "./components/WeAre";
import useGoogleAnalytics from "./components/useGoogleAnalytics";
import { useCookies } from "react-cookie";
import Privacy from "./components/Privacy";
import Disclaimer from "./components/Disclaimer";
import Volit from "./components/Volit";
import Nitrifix from "./components/Nitrifix";
import Surfect from "./components/Surfect";
import Integra from "./components/Integra";

const RoutesP = ({ setNavG, navG }) => {
  const [cookies, setCookies] = useCookies();
  const [cookieValue, setCookieValue] = useState(false);

  useGoogleAnalytics();

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Home
            setNavG={setNavG}
            setCookieValue={setCookieValue}
            cookieValue={cookieValue}
          />
        }
      ></Route>
      <Route
        exact
        path="/straights"
        element={<Straights setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/volit"
        element={<Volit setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/nitrifix"
        element={<Nitrifix setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/integra"
        element={<Integra setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/surfect"
        element={<Surfect setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/weare"
        element={<WeAre setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/privacy"
        element={<Privacy setNavG={setNavG} nav={navG} />}
      ></Route>
      <Route
        exact
        path="/disclaimer"
        element={<Disclaimer setNavG={setNavG} nav={navG} />}
      ></Route>
    </Routes>
  );
};

export default RoutesP;
