import React from "react";
import { useLocation } from "react-router-dom";

import analytics from "./analytics";

import { useCookies } from "react-cookie";

export default function useGoogleAnalytics() {
  const [cookies, setCookies] = useCookies();
  console.log(cookies["WGCookieConsent"]);
  const location = useLocation();

  React.useEffect(() => {
    analytics.init(cookies["WGCookieConsent"]);
    // analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);
}
