import React, { useEffect, useRef } from "react";
import "./Surfect.css";
import target1 from "../assets/_images/target1.png";
import target2 from "../assets/_images/target2.png";
import target3 from "../assets/_images/target3.png";
import target4 from "../assets/_images/target4.png";
import target5 from "../assets/_images/target5.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Surfect = ({ setNavG, navG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  const appeartween = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, duration: 0.3 },
          {
            duration: dur,
            alpha: 1,
          },
        ],
      }
    );
  };

  const reftarg1 = useRef(null);
  const refline1 = useRef(null);
  const reftrait1 = useRef(null);
  const reftarg2 = useRef(null);
  const refline2 = useRef(null);
  const reftrait2 = useRef(null);

  useEffect(() => {
    const creftarg1 = reftarg1.current;
    const crefline1 = refline1.current;
    const creftrait1 = reftrait1.current;
    appeartween(creftarg1, ".target1", 0.4, 0.2);
    appeartween(crefline1, ".target1", 0.8, 0.2);
    appeartween(creftrait1, ".target1", 0.8, 0.2);
    const creftarg2 = reftarg2.current;
    const crefline2 = refline2.current;
    const creftrait2 = reftrait2.current;
    appeartween(creftarg2, ".target2", 0.8, 0.2);
    appeartween(crefline2, ".target2", 1.2, 0.2);
    appeartween(creftrait2, ".target2", 1.2, 0.2);
  }, []);

  return (
    <div className="surfectbody">
      <div className="surfectmain">
        <div className="surfectmain-wrapper">
          <div className="surfectmaintag">
            <h1>SURFECT®</h1>
            <p>
              All of our WeProtect® products are optimized and powered by
              SURFECT® technology. SURFECT® is WeGrow’s customary solvent
              technology designed to ensure ease of handling, homogeneous and
              efficient application, storage longevity and more.
            </p>
          </div>
        </div>
      </div>
      <div className="surfect2">
        <div className="surfecttrait1">
          <img ref={reftarg1} className="target1" src={target1} alt="" />
          <div ref={refline1} className="target1line"></div>
          <div ref={reftrait1} className="strait1text">
            <h3>Low Viscocity</h3>
            <p>
              For homogeneous spraying. Low energy costs and minimal impact to
              your spraying application system.
            </p>
          </div>
        </div>
        <div className="surfecttrait2">
          <img ref={reftarg2} className="target2" src={target2} alt="" />
          <div ref={refline2} className="target2line"></div>
          <div ref={reftrait2} className="strait2text">
            <h3>Quick Dry</h3>
            <p>
              For quick and homogenous application. Avoid messy applications and
              improve color homogeneity.
            </p>
          </div>
        </div>
        <div className="surfecttrait3">
          <img className="target3" src={target3} alt="" />
          <div className="target3line"></div>
          <div className="strait3text">
            <h3>Active Ingredient Penetration</h3>
            <p>Make sure your active ingredient penetrates the granule.</p>
          </div>
        </div>
      </div>
      <div className="surfect3">
        <div className="surfecttrait4">
          <div className="strait4text">
            <h3>User Friendly</h3>
            <p>
              Elaborated with friendly ingredients for ease of handling and
              safety.
            </p>
          </div>
          <div className="target4line"></div>
          <img className="target4" src={target4} alt="" />
        </div>
        <div className="surfecttrait5">
          <div className="strait5text">
            <h3>Intense Customized Colors</h3>
            <p>
              Diferentiate your final product making it look great in a variety
              of colors of your choice.
            </p>
          </div>
          <div className="target5line"></div>
          <img className="target5" src={target5} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Surfect;
