import React, { useState, useEffect, useRef } from "react";
import Logoflat from "../assets/_images/logo-flat.svg";
import Logoflatwhite from "../assets/_images/logoflatwhite.svg";
import { Link } from "react-router-dom";
import "./Nav2.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NavHashLink } from "react-router-hash-link";

const Nav = ({ navG, setNavG }) => {
  //   const [nav, setNav] = useState(false);
  // const [navg, setNavg] = useState(false);

  gsap.registerPlugin(ScrollTrigger);

  const [dark, setDark] = useState(false);
  const [togglemobilemenu, setTogglemobilemenu] = useState(false);

  const showMobMenu = () => {
    setTogglemobilemenu(true);
  };
  const hideMobMenu = () => {
    setTogglemobilemenu(false);
  };
  const refMobMenu = useRef(null);
  useEffect(() => {
    if (togglemobilemenu === true) {
      const element = refMobMenu.current;
      gsap.fromTo(
        element,
        { x: 0 },
        { x: "-100%", display: "block", duration: 0.2 }
      );
    }
    if (togglemobilemenu === false) {
      const element = refMobMenu.current;
      gsap.fromTo(
        element,
        { x: "-100%" },
        { x: 0, duration: 0.2, display: "none", delay: 0.2 }
      );
    }
  }, [togglemobilemenu]);

  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <div
        className={
          navG && dark
            ? "navbarg displayblock navbargdark"
            : navG && !dark
            ? "navbarg displayblock"
            : "navbarg displaynone"
        }
      >
        <div className="navcontainerg">
          <div className="navitemsg">
            <Link to="/">
              {dark ? (
                <img src={Logoflatwhite} alt="" className="navlogo2" />
              ) : (
                <img src={Logoflat} alt="" className="navlogo2" />
              )}
            </Link>{" "}
            <ul className={dark ? "navg notmobile navgdark" : "navg notmobile"}>
              <li
                onMouseEnter={(e) => {
                  setExpanded(true);
                }}
                className="navtitle"
              >
                Our Products
                <ul
                  className={expanded ? "ddgexpand" : "ddghide"}
                  id="dropdowng"
                >
                  <div className="protnpknavlist">
                    <li>
                      <Link className="navgprodgroup" to="nitrogenstabilizers">
                        WeProtect
                        <sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Nitrogen Stabilizers</li>
                        <ul>
                          <li>
                            <Link
                              onClick={(e) => {
                                setExpanded(false);
                              }}
                              to="volit"
                            >
                              Volit<sup>&trade;</sup> - Urease Inhibitor
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={(e) => {
                                setExpanded(false);
                              }}
                              to="nitrifix"
                            >
                              NitrifiX<sup>&trade;</sup> - Nitrification
                              Inhibitor{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={(e) => {
                                setExpanded(false);
                              }}
                              to="integra"
                            >
                              Integra<sup>&trade;</sup> - Triple Action
                              Inhibitor
                            </Link>
                          </li>
                          <li>
                            <Link to="nurture">
                              Nurture<sup>&trade;</sup> - NextGen{" "}
                              <span className="lgnav">Nitrification </span>
                              Inhibitor
                            </Link>
                          </li>
                        </ul>
                        <li>Slow Release Fertilizers</li>
                        <ul>
                          <li>
                            Polymer Coated Urea{" "}
                            <span className="lgnav">(PCU)</span>
                          </li>
                        </ul>
                      </ul>
                    </li>

                    <li>
                      <Link className="navgprodgroup" to="NPK">
                        WeSolumix<sup>&trade;</sup>
                      </Link>
                    </li>
                    <ul>
                      <li>Soluble NPK</li>
                      <ul>
                        <li>
                          Click here to see a list of our main <br />
                          NPK grades. We also provide a <br />
                          wide range of customized grades.
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <li>
                    <Link className="navgprodgroup" to="straights">
                      WeSolve<sup>&trade;</sup>
                    </Link>
                    <div className="wesolvenavlist">
                      <div>
                        <ul>
                          <li>Nitrogen</li>
                          <ul>
                            <li>
                              Calcium Nitrate{" "}
                              <span className="lgnav">(CN)</span>
                            </li>
                            <li>
                              Magnesium Nitrate{" "}
                              <span className="lgnav">(MgN)</span>
                            </li>
                            <li>
                              Low Biuret Urea{" "}
                              <span className="lgnav">(LBU)</span>
                            </li>
                            <li>
                              Automotive Grade Urea{" "}
                              <span className="lgnav">(AGU)</span>
                            </li>
                          </ul>
                          <li>Phosphates</li>
                          <ul>
                            <li>
                              <Link to="straights">
                                Tech <span className="lgnav">nical</span>
                                Monoammonium Phosphate{" "}
                                <span className="lgnav">(TMAP)</span>
                              </Link>
                            </li>
                            <li>
                              {" "}
                              Tech <span className="lgnav">nical</span>
                              Diammonium Phosphate{" "}
                              <span className="lgnav">(TDAP)</span>
                            </li>
                            <li>
                              Mono Potassium Phosphate{" "}
                              <span className="lgnav">(MKP)</span>
                            </li>
                            <li>
                              Urea Phosphate <span className="lgnav">(UP)</span>
                            </li>
                          </ul>
                          <li>Potash</li>
                          <ul>
                            <li>
                              Potassium Nitrate{" "}
                              <span className="lgnav">(PN)</span>
                            </li>
                            <li>
                              Stabilized Potassium Nitrate{" "}
                              <span className="lgnav">(PNS)</span>
                            </li>
                            <li>
                              Sulfate of Potash
                              <span className="lgnav">(SOP)</span>
                            </li>
                            <li>
                              Soluble Muriate of Potash{" "}
                              <span className="lgnav">(SMOP)</span>
                            </li>
                            <li>
                              Potassium Citrate{" "}
                              <span className="lgnav">(PC)</span>
                            </li>
                          </ul>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>Magnesium</li>
                          <ul>
                            <li>
                              Magnesium Sulfate Mono{" "}
                              <span className="lgnav">(MgS Mono)</span>
                            </li>
                            <li>
                              Magnesium Sulfate Hepta{" "}
                              <span className="lgnav">(MgS Hepta)</span>
                            </li>
                            <li>
                              Magnesium Sulfate Anhydrate{" "}
                              <span className="lgnav">(MgS An)</span>
                            </li>
                            <li>
                              Magnesium Oxide
                              <span className="lgnav">(MgO)</span>
                            </li>
                          </ul>
                          <li>Micros</li>
                          <ul>
                            <li>
                              Copper Sulfate{" "}
                              <span className="lgnav">(CuS)</span>
                            </li>
                            <li>
                              Ferrous Sulfate{" "}
                              <span className="lgnav">(FeS)</span>
                            </li>
                            <li>
                              Manganses Sulfate{" "}
                              <span className="lgnav">(MnS)</span>
                            </li>
                            <li>
                              Calcium Chloride{" "}
                              <span className="lgnav">(CaCl)</span>
                            </li>
                          </ul>
                          <li>Boron</li>
                          <ul>
                            <li>Boron</li>
                            <li>Boric Acid</li>
                            <li>DOT</li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <div className="fluidorgnavlist">
                    <li>
                      <Link className="navgprodgroup" to="fluids">
                        WeFluids<sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Fluid Fertilizers</li>
                        <ul>
                          <li>
                            Ammonium Thiosulfate{" "}
                            <span className="lgnav">(ATS)</span>
                          </li>
                          <li>
                            Ammonium Polyphosphate{" "}
                            <span className="lgnav">(APP)</span>
                          </li>
                          <li>
                            Potassium Thiosulfate{" "}
                            <span className="lgnav">(KTS)</span>
                          </li>
                          <li>
                            Calcium Thiosulfate{" "}
                            <span className="lgnav">(CaTS)</span>
                          </li>
                          <li>
                            Phosphoric Acid <span className="lgnav">(PA)</span>{" "}
                            75% & 85%
                          </li>
                          <li>
                            Poly-phosphoric Acid{" "}
                            <span className="lgnav">(PPA)</span>
                          </li>
                        </ul>
                      </ul>
                    </li>
                    <li>
                      <Link className="navgprodgroup" to="organic">
                        WeOrganic<sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Organic Certified</li>
                        <ul>
                          <li>Epsomite</li>
                          <li>Kieserite</li>
                          <li>Chelates (EDTA)</li>
                          <li>
                            Sulfate of Potash{" "}
                            <span className="lgnav">(SOP)</span>
                          </li>
                        </ul>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
              {/* <li>We Learn</li> */}
              <li className="navtitle">
                <Link to="weare">We Are</Link>
              </li>
            </ul>
          </div>
          <div
            className={
              togglemobilemenu
                ? "mobile hamburger hideham display-none"
                : "mobile hamburger display-block"
            }
            onClick={showMobMenu}
          >
            <span className="bar2"></span>
            <span className="bar2"></span>
            <span className="bar2"></span>
          </div>
          <div
            className={
              togglemobilemenu
                ? "mobile closemobilemenu display-block"
                : "mobile hideclosemobmenu display-none"
            }
            onClick={(e) => {
              // setNavG(false);
              hideMobMenu();
            }}
            style={{ marginTop: "0.5rem" }}
          >
            {/* &#10060; */}&#x2715;
          </div>
          {/* <select
            className={
              dark ? "languageselect languageselectdark" : "languageselect"
            }
          >
            <option>ENG</option>
            <option value="">ESP</option>
          </select> */}
        </div>
        <div className="mobilemenu" ref={refMobMenu}>
          <ul>
            <li>Products</li>
            <ul className="mobilemenusub">
              <NavHashLink
                onClick={hideMobMenu}
                className="mobilemenulink"
                to="/#ourproducts"
                smooth={true}
              >
                <li>Nitrogen Stabilizers</li>
              </NavHashLink>
              <NavHashLink
                onClick={hideMobMenu}
                className="mobilemenulink"
                to="/#oursolubles"
              >
                <li>Water Solubles</li>
              </NavHashLink>
              <NavHashLink
                onClick={hideMobMenu}
                className="mobilemenulink"
                to="/#ourfluids"
              >
                <li>Fluid Fertilizers</li>
              </NavHashLink>
              <NavHashLink
                onClick={hideMobMenu}
                className="mobilemenulink"
                to="/#ournpk"
              >
                <li>Soluble NPKs</li>
              </NavHashLink>
              <NavHashLink
                onClick={hideMobMenu}
                className="mobilemenulink"
                to="/#ourorganic"
              >
                <li>Organic Certified</li>
              </NavHashLink>
            </ul>
            <Link to="/weare" onClick={hideMobMenu}>
              <li>We Are</li>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
