import React, { useState, useEffect, useRef } from "react";
import "./Home1.css";
import Logo from "../assets/_images/logowhite.svg";
import Bubble from "../assets/_images/bubble.png";
import InstaIcon from "../assets/_images/instaicon.png";
import LinkIcon from "../assets/_images/linkicon.png";
import FBIcon from "../assets/_images/fbicon.png";
import LeafIcon from "../assets/_images/leaficon.svg";
import LogisticsIcon from "../assets/_images/logisticsicon.svg";
import QualityIcon from "../assets/_images/qualityicon.svg";
import Volit from "../assets/_images/VolitTM.svg";
import Nitrifix from "../assets/_images/NitrifixTM.svg";
import Nurture from "../assets/_images/Nurture-TM.svg";
import Integra from "../assets/_images/IntegraTM.svg";
import volatilization from "../assets/_images/volatilization.svg";
import denitrification from "../assets/_images/denitrification.svg";
import leaching from "../assets/_images/leaching.svg";
import bgranules from "../assets/_images/bluegranules.png";
import pgranules from "../assets/_images/purplegranules.png";
import ggranules from "../assets/_images/greengranules.png";
import Logoflat from "../assets/_images/logo-flat.svg";
import ContactUsModal from "./ContactUsModal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import CookieConsent from "react-cookie-consent";
import useGaTracker from "./useGaTracker";

const Home = ({ setNavG, setCookieValue, cookieValue }) => {
  useGaTracker();

  gsap.registerPlugin(ScrollTrigger);

  const refsocialicons = useRef(null);
  const refvaluecard = useRef(null);
  const reftaghome = useRef(null);
  const reftagsolubles = useRef(null);
  const refcookie = useRef(null);

  const refMobMenu = useRef(null);

  useEffect(() => {
    setNavG(false);
  }, []);

  const customtween = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, y: 300, duration: 0.00001 },
          { delay: delay, alpha: 0.2, y: 150, duration: 0.3 },
          {
            duration: dur,
            y: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const reftaghomep = useRef(null);
  const reftaghomeb = useRef(null);
  const refval1h = useRef(null);
  const refval2h = useRef(null);
  const refval3h = useRef(null);
  const refval4h = useRef(null);
  const refval1p = useRef(null);
  const refval2p = useRef(null);
  const refval3p = useRef(null);
  const refval4p = useRef(null);
  const refvolit = useRef(null);
  const refnitrifix = useRef(null);
  const refintegra = useRef(null);
  const refnurture = useRef(null);
  const refnit = useRef(null);
  const refbor = useRef(null);
  const refphos = useRef(null);
  const refmg = useRef(null);
  const refpot = useRef(null);
  const refca = useRef(null);
  const reffluid = useRef(null);
  const refmix = useRef(null);
  const reforg = useRef(null);

  useEffect(() => {
    const element = refsocialicons.current;
    gsap.fromTo(
      element,
      { x: 200 },
      {
        duration: 0.4,
        x: 0,
        scrollTrigger: {
          trigger: element,
          // start: "top center",
          ease: "bounce",
        },
      }
    );
    const element1 = reftaghome.current;
    const el2 = reftaghomep.current;
    const el3 = reftaghomeb.current;
    const el4 = refval1h.current;
    const el5 = refval2h.current;
    const el6 = refval3h.current;
    const el7 = refval4h.current;
    const el8 = refval1p.current;
    const el9 = refval2p.current;
    const el10 = refval3p.current;
    const el11 = refval4p.current;
    const el12 = refvolit.current;
    const el13 = refnitrifix.current;
    const el14 = refintegra.current;
    const el15 = refnurture.current;
    const el16 = refnit.current;
    const el17 = refbor.current;
    const el18 = refphos.current;
    const el19 = refmg.current;
    const el20 = refpot.current;
    const el21 = refca.current;
    const el22 = reffluid.current;
    const el23 = refmix.current;
    const el24 = reforg.current;

    customtween(element1, element1, 0.2);
    customtween(el2, el2, 0.5);
    customtween(el3, el3, 0.5);
    // customtween(el4, ".valuecards", 0.3, 0.5);
    // customtween(el5, ".valuecards", 0.4, 0.5);
    // customtween(el8, ".valuecards", 0.6, 0.5);
    // customtween(el9, ".valuecards", 0.7, 0.5);
    // customtween(el6, ".valuecards", 0.7, 0.5);
    // customtween(el7, ".valuecards", 0.8, 0.5);
    // customtween(el10, ".valuecards", 1, 0.5);
    // customtween(el11, ".valuecards", 1.1, 0.5);
    // customtween(el12, ".inhibitorcards", 0.3, 0.5);
    // customtween(el13, ".inhibitorcards", 0.4, 0.5);
    // customtween(el14, ".inhibitorcards", 0.5, 0.5);
    // customtween(el15, ".inhibitorcards", 0.6, 0.5);
    // gsap.fromTo(
    //   element1,
    //   { y: 180, opacity: 0 },
    //   {
    //     duration: 0.8,
    //     y: 0,
    //     opacity: 1,
    //     scrollTrigger: {
    //       trigger: element1,
    //       ease: "bounce",
    //     },
    //   }
    // );

    gsap.fromTo(
      [el4, el8, el5, el9, el6, el10, el7, el11],
      // ".valuecard",
      { autoAlpha: 0, y: 300 },
      {
        duration: 0.5,
        y: 0,
        scrollTrigger: {
          trigger: el4,
          ease: "bounce",
        },
        autoAlpha: 1,
        stagger: 0.2,
      }
    );

    gsap.fromTo(
      [el12, el13, el14, el15],
      // ".valuecard",
      { autoAlpha: 0, y: 300 },
      {
        duration: 0.5,
        y: 0,
        scrollTrigger: {
          trigger: el12,
          ease: "bounce",
        },
        autoAlpha: 1,
        stagger: 0.2,
      }
    );

    gsap.fromTo(
      [el16, el17, el18, el19, el20, el21],
      // ".valuecard",
      { autoAlpha: 0, y: 300 },
      {
        duration: 0.5,
        y: 0,
        scrollTrigger: {
          trigger: el16,
          ease: "bounce",
        },
        autoAlpha: 1,
        stagger: 0.2,
      }
    );

    gsap.fromTo(
      [el22, el23, el24],
      // ".valuecard",
      { autoAlpha: 0, y: 300 },
      {
        duration: 0.7,
        y: 0,
        scrollTrigger: {
          trigger: el22,
          ease: "bounce",
        },
        autoAlpha: 1,
        stagger: 0.2,
      }
    );

    const element2 = reftagsolubles.current;
    gsap.fromTo(
      element2,
      { x: 300 },
      {
        duration: 0.5,
        x: 0,
        scrollTrigger: {
          trigger: element2,
          ease: "bounce",
        },
      }
    );
    const cookieelement = refcookie.current;
    gsap.fromTo(
      cookieelement,
      { y: 200 },
      {
        duration: 1.5,
        x: 0,
        scrollTrigger: {
          trigger: cookieelement,
          ease: "bounce",
        },
      }
    );
    // const element2 = refvaluecard.current;
    // gsap.fromTo(
    //   element2,
    //   { y: 100 },
    //   {
    //     duration: 0.4,
    //     y: 0,
    //     scrollTrigger: {
    //       trigger: element2,
    //       ease: "bounce",
    //     },
    //   }
    // );
  }, []);

  const [nav, setNav] = useState(false);

  const changebgd = () => {
    if (window.scrollY >= 150) {
      setNav(true);
    } else {
      setNav(false);
      setTogglemobilemenu(false);
    }
  };

  window.addEventListener("scroll", changebgd);

  const [showcontactus, setShowcontactus] = useState(false);

  const [togglemobilemenu, setTogglemobilemenu] = useState(false);

  const showMobMenu = () => {
    setTogglemobilemenu(true);
  };
  const hideMobMenu = () => {
    setTogglemobilemenu(false);
  };

  useEffect(() => {
    if (togglemobilemenu === true) {
      const element = refMobMenu.current;
      gsap.fromTo(
        element,
        { x: 0 },
        { x: "-100%", display: "block", duration: 0.2 }
      );
    }
    if (togglemobilemenu === false) {
      const element = refMobMenu.current;
      gsap.fromTo(
        element,
        { x: "-100%" },
        { x: 0, duration: 0.2, display: "none", delay: 0.2 }
      );
    }
  }, [togglemobilemenu]);

  return (
    <div className="wrapper">
      <CookieConsent
        // customContainerAttributes={{ ref: { refcookie } }}
        location="bottom"
        cookieName="WGCookieConsent"
        enableDeclineButton
        buttonText="Accept all cookies"
        declineButtonText="Customize"
        containerClasses="cookiecontainer"
        flipButtons={true}
        buttonWrapperClasses="cookiebuttonwrapper"
        contentClasses="contentcookie"
        style={{
          width: "80%",
          margin: "0 auto",
          // left: "7%",
          backgroundColor: "rgba(255,255,255)",
          height: "200px",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          padding: "1rem 3rem",
          color: "gray",
          fontFamily: "'Noto Sans', sans-serif",
          boxShadow: "0px -5px 27px 0px rgba(128, 128, 128, 0.58)",
          alignItems: "top",
          animation: "cookieanimation 1000ms forwards",
        }}
        contentStyle={{ width: "50%", flex: "50%", marginTop: "2rem" }}
        buttonStyle={{
          padding: "1rem 3rem",
          backgroundColor: "var(--blue)",
          color: "white",
          fontFamily: "'Noto Sans', sans-serif",
          fontSize: "1rem",
          borderRadius: "0.25rem",
        }}
        declineButtonStyle={{
          border: "1px solid var(--blue)",
          backgroundColor: "white",
          color: "black",
          padding: "1rem 3rem",
          fontFamily: "'Noto Sans', sans-serif",
          fontSize: "1rem",
          borderRadius: "0.25rem",
        }}
        onAccept={(e) => {
          window.location.reload(false);
        }}
      >
        <h3 style={{ margin: "0", marginBottom: "1rem" }}>
          Cookies on this website
        </h3>
        <p>
          This website uses cookies to provide you with the best possible user
          experience by remembering your preferences and repeat visits. Any data
          collected is aggregated or anonymized. By clicking on "Accept all
          cookies" you consent to the use of ALL cookies.
        </p>{" "}
        <p>
          <Link
            to="/privacy"
            style={{ textDecoration: "underline", color: "black" }}
          >
            Privacy Policy
          </Link>
        </p>
      </CookieConsent>
      <ContactUsModal
        showcontactus={showcontactus}
        setShowcontactus={setShowcontactus}
      />
      <div className="mainhome">
        <div className={nav ? "navbar2 displayblock" : "navbar2 displaynone"}>
          <div className="navcontainer2">
            <div className="navitems2">
              <Link to="/">
                <img src={Logoflat} alt="" className="navlogo2" />
              </Link>{" "}
              <ul className="nav2 notmobile">
                <li className="navtitle">
                  Our Products
                  <ul id="dropdown">
                    <div className="protnpknavlist">
                      <li>
                        <Link
                          className="navgprodgroup"
                          to="nitrogenstabilizers"
                        >
                          WeProtect
                          <sup>&trade;</sup>
                        </Link>
                        <ul>
                          <li>Nitrogen Stabilizers</li>
                          <ul>
                            <li>
                              <Link to="volit">
                                {" "}
                                Volit<sup>&trade;</sup> - Urease Inhibitor
                              </Link>
                            </li>
                            <li>
                              <Link to="nitrifix">
                                NitrifiX<sup>&trade;</sup> - Nitrification
                                Inhibitor
                              </Link>{" "}
                            </li>
                            <li>
                              <Link to="integra">
                                Integra<sup>&trade;</sup> - Triple Action
                                Inhibitor
                              </Link>
                            </li>
                            <li>
                              <Link to="nurture">
                                Nurture<sup>&trade;</sup> - NextGen{" "}
                                <span className="lgnav">Nitrification </span>
                                Inhibitor
                              </Link>
                            </li>
                          </ul>
                          <li>Slow Release Fertilizers</li>
                          <ul>
                            <li>
                              Polymer Coated Urea{" "}
                              <span className="lgnav">(PCU)</span>
                            </li>
                          </ul>
                        </ul>
                      </li>

                      <li>
                        <Link className="navgprodgroup" to="NPK">
                          WeSolumix<sup>&trade;</sup>
                        </Link>
                        <ul>
                          <li>Soluble NPK</li>
                          <ul>
                            <li>
                              Click here to see a list of our main <br />
                              NPK grades. We also provide a <br />
                              wide range of customized grades.
                            </li>
                          </ul>
                        </ul>
                      </li>
                    </div>
                    <li>
                      <Link className="navgprodgroup" to="straights">
                        WeSolve<sup>&trade;</sup>
                      </Link>
                      <div className="wesolvenavlist">
                        <div>
                          <ul>
                            <li>Nitrogen</li>
                            <ul>
                              <li>
                                Calcium Nitrate{" "}
                                <span className="lgnav">(CN)</span>
                              </li>
                              <li>
                                Magnesium Nitrate{" "}
                                <span className="lgnav">(MgN)</span>
                              </li>
                              <li>
                                Low Biuret Urea{" "}
                                <span className="lgnav">(LBU)</span>
                              </li>
                              <li>
                                Automotive Grade Urea{" "}
                                <span className="lgnav">(AGU)</span>{" "}
                              </li>
                            </ul>
                            <li>Phosphates</li>
                            <ul>
                              <li>
                                <Link to="straights">
                                  Tech <span className="lgnav">nical</span>
                                  Monoammonium Phosphate{" "}
                                  <span className="lgnav">(TMAP)</span>
                                </Link>
                              </li>
                              <li>
                                Tech <span className="lgnav">nical</span>
                                Diammonium Phosphate{" "}
                                <span className="lgnav">(TDAP)</span>
                              </li>
                              <li>
                                Mono Potassium Phosphate{" "}
                                <span className="lgnav">(MKP)</span>
                              </li>
                              <li>
                                Urea Phosphate{" "}
                                <span className="lgnav">(UP)</span>
                              </li>
                            </ul>
                            <li>Potash</li>
                            <ul>
                              <li>
                                Potassium Nitrate{" "}
                                <span className="lgnav">(PN)</span>
                              </li>
                              <li>
                                Stabilized Potassium Nitrate{" "}
                                <span className="lgnav">(PNS)</span>
                              </li>
                              <li>
                                Sulfate of Potash{" "}
                                <span className="lgnav">(SOP)</span>
                              </li>
                              <li>
                                Soluble Muriate of Potash{" "}
                                <span className="lgnav">(SMOP)</span>
                              </li>
                              <li>
                                Potassium Citrate{" "}
                                <span className="lgnav">(PC)</span>
                              </li>
                            </ul>
                          </ul>
                        </div>
                        <div>
                          <ul>
                            <li>Magnesium</li>
                            <ul>
                              <li>
                                Magnesium Sulfate Mono{" "}
                                <span className="lgnav">(MgS Mono)</span>
                              </li>
                              <li>
                                Magnesium Sulfate Hepta{" "}
                                <span className="lgnav">(MgS Hepta)</span>
                              </li>
                              <li>
                                Magnesium Sulfate Anhydrate{" "}
                                <span className="lgnav">(MgS An)</span>
                              </li>
                              <li>
                                Magnesium Oxide{" "}
                                <span className="lgnav">(MgO)</span>
                              </li>
                            </ul>
                            <li>Micros</li>
                            <ul>
                              <li>
                                Copper Sulfate{" "}
                                <span className="lgnav">(CuS)</span>
                              </li>
                              <li>
                                Ferrous Sulfate{" "}
                                <span className="lgnav">(FeS)</span>
                              </li>
                              <li>
                                Manganese Sulfate{" "}
                                <span className="lgnav">(MnS)</span>
                              </li>
                              <li>
                                Calcium Chloride{" "}
                                <span className="lgnav">(CaCl)</span>
                              </li>
                            </ul>
                            <li>Boron</li>
                            <ul>
                              <li>Boron</li>
                              <li>Boric Acid</li>
                              <li>DOT</li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <div className="fluidorgnavlist">
                      <li>
                        <Link className="navgprodgroup" to="fluids">
                          WeFluids<sup>&trade;</sup>
                        </Link>
                        <ul>
                          <li>Fluid Fertilizers</li>
                          <ul>
                            <li>
                              Ammonium Thiosulfate{" "}
                              <span className="lgnav">(ATS)</span>
                            </li>
                            <li>
                              Ammonium Polyphosphate{" "}
                              <span className="lgnav">(APP)</span>
                            </li>
                            <li>
                              Potassium Thiosulfate{" "}
                              <span className="lgnav">(KTS)</span>
                            </li>
                            <li>
                              Calcium Thiosulfate{" "}
                              <span className="lgnav">(CaTS)</span>
                            </li>
                            <li>
                              Phosphoric Acid{" "}
                              <span className="lgnav">(PA)</span> 75% & 85%
                            </li>
                            <li>
                              Poly-phosphoric Acid{" "}
                              <span className="lgnav">(PPA)</span>
                            </li>
                          </ul>
                        </ul>
                      </li>
                      <li>
                        <Link className="navgprodgroup" to="organic">
                          WeOrganic<sup>&trade;</sup>
                        </Link>
                        <ul>
                          <li>Organic Certified</li>
                          <ul>
                            <li>Epsomite</li>
                            <li>Kieserite</li>
                            <li>Chelates (EDTA)</li>
                            <li>
                              Sulfate of Potash{" "}
                              <span className="lgnav">(SOP)</span>
                            </li>
                          </ul>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </li>
                {/* <li>We Learn</li> */}
                <li className="navtitle">
                  <NavHashLink to="/weare/#">We Are</NavHashLink>
                </li>
              </ul>
            </div>
            <div
              className={
                togglemobilemenu
                  ? "mobile hamburger hideham display-none"
                  : "mobile hamburger display-block"
              }
              onClick={showMobMenu}
            >
              <span className="bar2"></span>
              <span className="bar2"></span>
              <span className="bar2"></span>
            </div>
            <div
              className={
                togglemobilemenu
                  ? "mobile closemobilemenu display-block"
                  : "mobile hideclosemobmenu display-none"
              }
              onClick={(e) => {
                setNav(false);
                hideMobMenu();
              }}
              style={{ marginTop: "0.5rem" }}
            >
              {/* &#10060; */}&#x2715;
            </div>
            {/* <select className="languageselect">
              <option>ENG</option>
              <option value="">ESP</option>
            </select> */}
          </div>
          <div className="mobilemenu" ref={refMobMenu}>
            <ul>
              <li>Products</li>
              <ul className="mobilemenusub">
                <HashLink
                  onClick={hideMobMenu}
                  className="mobilemenulink"
                  to="#ourproducts"
                  smooth={true}
                >
                  <li>Nitrogen Stabilizers</li>
                </HashLink>
                <HashLink
                  onClick={hideMobMenu}
                  className="mobilemenulink"
                  to="#oursolubles"
                >
                  <li>Water Solubles</li>
                </HashLink>
                <HashLink
                  onClick={hideMobMenu}
                  className="mobilemenulink"
                  to="#ourfluids"
                >
                  <li>Fluid Fertilizers</li>
                </HashLink>
                <HashLink
                  onClick={hideMobMenu}
                  className="mobilemenulink"
                  to="#ournpk"
                >
                  <li>Soluble NPKs</li>
                </HashLink>
                <HashLink
                  onClick={hideMobMenu}
                  className="mobilemenulink"
                  to="#ourorganic"
                >
                  <li>Organic Certified</li>
                </HashLink>
              </ul>
              <NavHashLink to="/weare/#" onClick={hideMobMenu}>
                <li>We Are</li>
              </NavHashLink>
            </ul>
          </div>
        </div>

        {/* <img className="bubble" src={Bubble} alt="" /> */}
        <div className="navbar">
          <div className="navcontainer">
            <img className="navlogo" src={Logo} alt="" />

            <ul className="nav notmobile">
              <li className="navtitle">
                Our Products
                <ul id="dropdown3">
                  <div className="protnpknavlist">
                    <li>
                      <Link className="navgprodgroup" to="nitrogenstabilizers">
                        WeProtect
                        <sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Nitrogen Stabilizers</li>
                        <ul>
                          <li>
                            <Link to="volit">
                              Volit<sup>&trade;</sup> - Urease Inhibitor
                            </Link>
                          </li>
                          <li>
                            <Link to="nitrifix">
                              NitrifiX<sup>&trade;</sup> - Nitrification
                              Inhibitor{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="integra">
                              Integra<sup>&trade;</sup> - Triple Action
                              Inhibitor
                            </Link>
                          </li>
                          <li>
                            <Link to="nurture">
                              Nurture<sup>&trade;</sup> - NextGen{" "}
                              <span className="lgnav">Nitrification</span>{" "}
                              Inhibitor
                            </Link>
                          </li>
                        </ul>
                        <li>Slow Release Fertilizers</li>
                        <ul>
                          <li>Polymer Coated Urea (PCU)</li>
                        </ul>
                      </ul>
                    </li>

                    <li>
                      <Link className="navgprodgroup" to="NPK">
                        WeSolumix<sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Soluble NPK</li>
                        <ul>
                          <li>
                            Click here to see a list of our main <br />
                            NPK grades. We also provide a <br />
                            wide range of customized grades.
                          </li>
                        </ul>
                      </ul>
                    </li>
                  </div>
                  <li>
                    <Link className="navgprodgroup" to="straights">
                      WeSolve<sup>&trade;</sup>
                    </Link>
                    <div className="wesolvenavlist">
                      <div>
                        <ul>
                          <li>Nitrogen</li>
                          <ul>
                            <li>
                              Calcium Nitrate{" "}
                              <span className="lgnav">(CN)</span>
                            </li>
                            <li>
                              Magnesium Nitrate{" "}
                              <span className="lgnav">(MgN)</span>
                            </li>
                            <li>
                              Low Biuret Urea{" "}
                              <span className="lgnav">(LBU)</span>
                            </li>
                            <li>
                              Automotive Grade Urea{" "}
                              <span className="lgnav">(AGU)</span>
                            </li>
                          </ul>
                          <li>Phosphates</li>
                          <ul>
                            <li>
                              <Link to="straights">
                                Tech<span className="lgnavtxt">nical</span>{" "}
                                Monoammonium Phosphate{" "}
                                <span className="lgnav">(TMAP)</span>
                              </Link>
                            </li>
                            <li>
                              Tech<span className="lgnavtxt">nical</span>{" "}
                              Diammonium Phosphate{" "}
                              <span className="lgnav">(TDAP)</span>
                            </li>
                            <li>
                              Mono Potassium Phosphate{" "}
                              <span className="lgnav">(MKP)</span>
                            </li>
                            <li>
                              Urea Phosphate <span className="lgnav">(UP)</span>
                            </li>
                          </ul>
                          <li>Potash</li>
                          <ul>
                            <li>
                              Potassium Nitrate{" "}
                              <span className="lgnav">(PN)</span>
                            </li>
                            <li>
                              Stabilized Potassium Nitrate{" "}
                              <span className="lgnav">(PNS)</span>
                            </li>
                            <li>
                              Sulfate of Potash{" "}
                              <span className="lgnav"> (SOP)</span>
                            </li>
                            <li>
                              Soluble Muriate of Potash{" "}
                              <span className="lgnav">(SMOP)</span>
                            </li>
                            <li>
                              Potassium Citrate{" "}
                              <span className="lgnav">(PC)</span>
                            </li>
                          </ul>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>Magnesium</li>
                          <ul>
                            <li>
                              Magnesium Sulfate Mono{" "}
                              <span className="lgnav">(MgS Mono)</span>
                            </li>
                            <li>
                              Magnesium Sulfate Hepta{" "}
                              <span className="lgnav">(MgS Hepta)</span>
                            </li>
                            <li>
                              Magnesium Sulfate Anhydrate{" "}
                              <span className="lgnav">(MgS An)</span>
                            </li>
                            <li>
                              Magnesium Oxide{" "}
                              <span className="lgnav">(MgO)</span>
                            </li>
                          </ul>
                          <li>Micros</li>
                          <ul>
                            <li>
                              Copper Sulfate{" "}
                              <span className="lgnav">(CuS)</span>
                            </li>
                            <li>
                              Ferrous Sulfate{" "}
                              <span className="lgnav">(FeS)</span>
                            </li>
                            <li>
                              Manganses Sulfate{" "}
                              <span className="lgnav">(MnS)</span>
                            </li>
                            <li>
                              Calcium Chloride{" "}
                              <span className="lgnav">(CaCl)</span>
                            </li>
                          </ul>
                          <li>Boron</li>
                          <ul>
                            <li>Boron</li>
                            <li>Boric Acid</li>
                            <li>DOT</li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <div className="fluidorgnavlist">
                    <li>
                      <Link className="navgprodgroup" to="fluids">
                        WeFluids<sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Fluid Fertilizers</li>
                        <ul>
                          <li>
                            Ammonium Thiosulfate{" "}
                            <span className="lgnav">(ATS)</span>
                          </li>
                          <li>
                            Ammonium Polyphosphate{" "}
                            <span className="lgnav">(APP)</span>
                          </li>
                          <li>
                            Potassium Thiosulfate{" "}
                            <span className="lgnav">(KTS)</span>
                          </li>
                          <li>
                            Calcium Thiosulfate{" "}
                            <span className="lgnav">(CaTS)</span>
                          </li>
                          <li>
                            Phosphoric Acid <span className="lgnav">(PA)</span>{" "}
                            75% & 85%
                          </li>
                          <li>
                            Poly-phosphoric Acid{" "}
                            <span className="lgnav">(PPA)</span>
                          </li>
                        </ul>
                      </ul>
                    </li>
                    <li>
                      <Link className="navgprodgroup" to="organic">
                        WeOrganic<sup>&trade;</sup>
                      </Link>
                      <ul>
                        <li>Organic Certified</li>
                        <ul>
                          <li>Epsomite</li>
                          <li>Kieserite</li>
                          <li>Chelates</li>
                          <li>
                            Sulfate of Potash{" "}
                            <span className="lgnav">(SOP)</span>
                          </li>
                          <li>
                            Zinc Sulfate <span className="lgnav">(ZnS)</span>
                          </li>
                        </ul>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
              {/* <li>We Learn</li> */}
              <li className="navtitle">
                <Link
                  // style={{ color: "white", textDecoration: "none" }}
                  to="weare"
                >
                  We Are
                </Link>
              </li>
            </ul>
            <div
              className="mobile hamburger"
              // className={
              //   togglemobilemenu
              //     ? "hamburger mobile display-none"
              //     : "hamburger mobile display-block"
              // }
              // onClick={showMobMenu}
              onClick={(e) => {
                e.preventDefault();
                setNav(true);
                showMobMenu();
              }}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </div>
        </div>
        <div className="homepage">
          <div className="taghome">
            <h1 ref={reftaghome}>
              Grow sustainable. <br /> Grow with us.
            </h1>
            <h5 ref={reftaghomep}>
              We are a leading global provider of specialty agricultural
              nutrition products and a producer of nutrient use efficiency
              solutions.
            </h5>
            <div className="homebuttons" ref={reftaghomeb}>
              <HashLink to="#ourproducts" smooth>
                <button className="mainbutton">See Our Products</button>
              </HashLink>
              <a href="#">
                <button
                  // style={{ marginRight: 0 }}
                  className="mainbutton"
                  onClick={(e) => {
                    setShowcontactus(true);
                  }}
                >
                  Contact an Expert
                </button>
              </a>
            </div>
          </div>
          {/* <div ref={refsocialicons} className="socialicons">
            <img src={FBIcon} alt="" />
            <img src={InstaIcon} alt="" />
            <img src={LinkIcon} alt="" />
          </div> */}
        </div>
      </div>
      <div className="wgvalues">
        <div className="wgvalues-wrapper">
          <h1>WeAre all about.</h1>
          <div className="valuecards">
            <div ref={refvaluecard} className="valuecard">
              <img className="valueicon" src={LeafIcon} alt="" />
              <div className="valuecard-text">
                <h2 ref={refval1h}>Technology Serving the Environment</h2>
                <p ref={refval1p}>
                  We strive to promote and implement the most efficient products
                  and solutions to minimize the over application of nutrients
                  and the resulting waste which is negatively affecting
                  ecosystems globally.
                </p>
              </div>
            </div>{" "}
            <div className="valuecard">
              <img className="valueicon" src={LeafIcon} alt="" />
              <div className="valuecard-text">
                <h2 ref={refval2h}>Offering Complete Solutions</h2>
                <p ref={refval2p}>
                  More than just a complete product portfolio, we work to
                  provide our customers with holistic solutions that aim to
                  achieve true efficiency in modern sustainable farming systems.
                </p>
              </div>
            </div>
            <div ref={refvaluecard} className="valuecard">
              <img className="valueicon" src={LogisticsIcon} alt="" />
              <div className="valuecard-text">
                <h2 ref={refval3h}>Expert Logistics</h2>
                <p ref={refval3p}>
                  We are specialists in logistics with more than 20 years of
                  experience actively seeking custom solutions to ensure the
                  most efficient & competitive delivery. We focus on
                  quality-conscious and effective delivery by working with
                  selected independent inspectors to survey the manufacturing,
                  storing, packaging and container stuffing, certifying that
                  quality & quantities are in order.
                </p>
              </div>
            </div>
            <div className="valuecard">
              <img className="valueicon" src={QualityIcon} alt="" />
              <div className="valuecard-text">
                <h2 ref={refval4h}>Quality Assurance</h2>
                <p ref={refval4p}>
                  We do systematic inspections and product validation processes
                  to ensure product quality before we ever put them in the
                  market. Often we test it ourselves first via our own
                  distribution channels and in collaboration with our closest
                  partners. On a regular basis, we visit our suppliers,
                  warehouses and load ports to audit consistent quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inhibitors" id="ourproducts">
        {/* <div className="inhibitors-wrapper"></div> */}
        <div className="inhibitorcards-section">
          <div className="inhibitorcards-wrapper">
            <h1>
              WeProtect<sup>&trade;</sup> your Nitrogen.
            </h1>
            <h2>Nitrogen Stabilizers</h2>
            <h3>
              WeProtect<sup>&trade; </sup> is WeGrow's line of nitrogen
              stabilizers. WeProtect<sup>&trade; </sup> products are optimized
              with SURFECT
              <sup>&trade; </sup>
              technology to make sure that your Nitrogen is protected to the
              max. SURFECT<sup>&trade; </sup> technology is designed to optimize
              granule penetration and storage longevity under recommended
              conditions making sure more of your Nitrogen reaches your crop.
            </h3>
            <div className="inhibitorcards">
              <div ref={refvolit} className="inhibitorcard">
                <img className="volitlogo" src={Volit} />
                <p>
                  Volit<sup className="inhibtm">TM </sup> is WeGrow’s new Urease
                  Inhibitor. Volit
                  <sup className="inhibtm">TM </sup>
                  protects your Ureic Nitrogen from Urease allowing it to
                  incorporate into the soil before it gets hydrolyzed. The
                  result is a more efficient use of your Nitrogen and lower
                  losses due to volatilization.
                </p>
                <div className="inhibitorcard-bottom">
                  <div className="inhibiticons">
                    <img className="inhibiticon" src={volatilization} />
                  </div>
                  <Link to="volit">
                    <button className="inhibitorbutton">Learn More</button>
                  </Link>
                </div>
              </div>
              <div ref={refnitrifix} className="inhibitorcard">
                <img className="nitrifixlogo" src={Nitrifix} />
                <p>
                  NitrifiX<sup className="inhibtm">TM </sup> is WeGrow’s new
                  Nitrification Inhibitor. NitrifiX
                  <sup className="inhibtm">TM </sup>
                  increases the efficiency of your Nitrogen by keeping it in
                  Ammonium form for a longer period so that the crop has more
                  time to absorb it. Less of your Nitrogen is lost to leaching
                  or denitrification.
                </p>
                <div className="inhibitorcard-bottom">
                  <div className="inhibiticons">
                    <img className="inhibiticon" src={leaching} />
                    <img className="inhibiticon" src={denitrification} />
                  </div>
                  <Link to="nitrifix">
                    <button className="inhibitorbutton">Learn More</button>
                  </Link>
                </div>
              </div>
              <div ref={refintegra} className="inhibitorcard">
                <img className="integralogo" src={Integra} />
                <p>
                  For the most complete protection, Integra
                  <sup className="inhibtm">TM </sup> combines the protective
                  properties of VoLit<sup className="inhibtm">TM </sup> and
                  NitrifiX<sup className="inhibtm">TM </sup>providing your
                  Nitrogen with a triple shield against volatilization, leaching
                  and denitrification losses.
                </p>
                <div className="inhibitorcard-bottom">
                  <div className="inhibiticons">
                    <img className="inhibiticon" src={volatilization} />
                    <img className="inhibiticon" src={leaching} />
                    <img className="inhibiticon" src={denitrification} />
                  </div>{" "}
                  <Link to="integra">
                    <button className="inhibitorbutton">Learn More</button>
                  </Link>
                </div>
              </div>
              <div ref={refnurture} className="inhibitorcard">
                <img className="nurturelogo" src={Nurture} />
                <p>
                  NURTURE<sup>TM</sup> next generation Nitrification Inhibitor
                  helps your nitrogen stay in Ammonia/Ammonium form for a longer
                  period allowing plants more time to absorb it. Protection
                  against both leaching and denitrification losses.
                </p>
                <div className="inhibitorcard-bottom">
                  <div className="inhibiticons">
                    <img className="inhibiticon" src={leaching} />
                    <img className="inhibiticon" src={denitrification} />
                  </div>
                  <button className="inhibitorbutton">Learn More</button>
                </div>
              </div>
            </div>
            <div className="inhibitorphotos">
              <img className="granulephoto" src={bgranules} />
              <img className="granulephoto" src={pgranules} />
              <img className="granulephoto" src={ggranules} />
            </div>
          </div>
        </div>
      </div>
      <div className="solubles" id="oursolubles">
        <div ref={reftagsolubles} className="solublestag">
          <button
            onClick={(e) => {
              setShowcontactus(true);
            }}
            className="solublestagbutton"
          >
            Contact an Expert
          </button>
        </div>
        <div className="solubles-wrapper">
          <h1>
            WeSolve<sup>&trade; </sup>
            your <br />
            crop nutritional needs.
          </h1>
          <h2>Water Soluble Fertilizers</h2>
          <p>
            WeSolve<sup>&trade; </sup>is our line of straight water soluble
            fertilizers. Our focus is on quality and reliability.{" "}
          </p>
          <div className="solublecards">
            <div className="solublecards-left">
              <div ref={refnit} className="solublecard">
                <h1>Nitrogen</h1>
                <ul>
                  <li>
                    Calcium Nitrate <span className="sollgtxt">(CN)</span>
                  </li>
                  <li>
                    Magnesium Nitrate <span className="sollgtxt">(MgN)</span>
                  </li>
                  <li>
                    Low Biuret Urea <span className="sollgtxt">(LBU)</span>
                  </li>
                  <li>
                    Automotive Grade Urea{" "}
                    <span className="sollgtxt">(AGU)</span>
                  </li>
                </ul>
              </div>
              <div ref={refphos} className="solublecard">
                <h1>Phosphates</h1>
                <ul>
                  <li>
                    Tech<span className="sollgtxt">nical </span>{" "}
                    <span className="notmobile">Monoammonium Phosphate </span>{" "}
                    <span className="mobile mobilelmar"> MAP</span>
                    <span className="sollgtxt">(TMAP)</span>
                  </li>
                  <li>
                    Tech<span className="sollgtxt">nical</span>{" "}
                    <span className="notmobile">Diammonium Phosphate</span>
                    <span className="mobile mobilelmar"> DAP</span>{" "}
                    <span className="sollgtxt">(TDAP)</span>
                  </li>
                  <li>
                    <span className="notmobile">Mono Potassium Phosphate</span>
                    <span className="mobile"> MKP</span>{" "}
                    <span className="sollgtxt">(MKP)</span>
                  </li>
                  <li>
                    Urea Phosphate <span className="sollgtxt">(UP)</span>
                  </li>
                  <li>
                    Phosphoric Acid <span className="sollgtxt">(PA)</span>
                  </li>
                </ul>
              </div>
              <div ref={refpot} className="solublecard">
                <h1>Potash</h1>
                <ul>
                  <li>Potassium Nitrate (PN)</li>
                  <li>
                    <span className="notmobile">Potassium Nitrate</span>{" "}
                    <span className="mobile mobilermar">PN</span> Stablized{" "}
                    <span className="sollgtxt">(PNS)</span>
                  </li>
                  <li>
                    Sulfate of Potash <span className="sollgtxt">(SOP)</span>
                  </li>
                  <li>
                    Muriate of Potash <span className="sollgtxt">(MOP)</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="solublecards-right">
              <div ref={refbor} className="solublecard">
                <h1>Boron</h1>
                <ul>
                  <li>
                    Boric Acid <span className="sollgtxt">(B Acid)</span>
                  </li>
                  <li>
                    Boron <span className="sollgtxt">(B)</span>
                  </li>
                  <li>DOT</li>
                </ul>
              </div>

              <div ref={refmg} className="solublecard">
                <h1>Magnesium</h1>
                <ul>
                  <li>
                    Magnesium Nitrate <span className="sollgtxt">(MgN)</span>
                  </li>
                  <li>
                    Magnesium Oxide <span className="sollgtxt">(MgO)</span>
                  </li>
                  <li>
                    <span className="notmobile">Magnesium Sulfate </span>{" "}
                    <span className="mobile mobilermar">MgS</span>Monohydrate{" "}
                    <span className="sollgtxt">(MgS Mono)</span>
                  </li>
                  <li>
                    <span className="notmobile">Magnesium Sulfate </span>{" "}
                    <span className="mobile mobilermar">MgS</span>
                    Heptahydrate <span className="sollgtxt">(MgS Hepta)</span>
                  </li>
                  <li>
                    <span className="notmobile">Magnesium Sulfate </span>{" "}
                    <span className="mobile mobilermar">MgS</span> Anhydrate{" "}
                    <span className="sollgtxt">(MgS Anh)</span>
                  </li>
                  <li>
                    Epsomite Salt <span className="sollgtxt">(Epsom)</span>
                  </li>
                  <li>
                    Kieserite <span className="sollgtxt">(Kieserite)</span>
                  </li>
                </ul>
              </div>
              <div ref={refca} className="solublecard">
                <h1>Calcium</h1>
                <ul>
                  <li>
                    Calcium Nitrate <span className="sollgtxt">(CN)</span>
                  </li>
                  <li>
                    Calcium Chloride <span className="sollgtxt">(CaCl)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="other">
        <div className="other-wrapper">
          <div ref={reffluid} className="fluids othercol" id="ourfluids">
            <h1 className="othertitle">
              WeFluids<sup>&trade;</sup>
            </h1>
            <p>
              WeFluids<sup>&trade;</sup> has you covered when it comes to high
              quality fluid fertilizers:
            </p>
            <ul>
              <li>UAN - Urea Ammonium Nitrate</li>
              <li>KTS - Potassium Thiosulfate</li>
              <li>ATS - Ammonium Thiosulfate</li>
              <li>CaTS - Calcium Thiosulfate</li>
              <li>...and More</li>
            </ul>
            <button className="otherbutton">
              Contact Us About WeFluids<sup>&trade; </sup>
            </button>
          </div>
          <div ref={refmix} className="solumix othercol" id="ournpk">
            <h1 className="othertitle">
              WeSolumix<sup>&trade;</sup>
            </h1>
            <p>Ask about our full line of water soluble NPK blends. </p>
            <ul>
              <li>High K - NPK</li>
              <li>High N - NPK</li>
              <li>High P - NPK</li>
              <li>Balanced - NPK</li>
              <li>Customized Blends</li>
            </ul>
            <button className="otherbutton">
              Contact Us About WeSolumix<sup>&trade; </sup>
            </button>
          </div>
          <div ref={reforg} className="organic othercol" id="ourorganic">
            <h1 className="othertitle">
              WeOrganic<sup>&trade;</sup>
            </h1>
            <p>
              WeOrganic<sup>&trade;</sup> line focuses on organic certified
              sources of N, P, K and Micros:
            </p>
            <ul>
              <li>Chelates</li>
              <li>EDTA</li>
              <li>OMRI certified sources of K</li>
              <li>Epsomite</li>
              <li>...and More</li>
            </ul>
            <button className="otherbutton">
              Contact Us About WeOrganic<sup>&trade; </sup>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
