import React, { useEffect, useRef } from "react";
import nitrifixlogo from "../assets/_images/NitrifixTM.svg";
import integramain from "../assets/_images/integramain.svg";
import leachicon from "../assets/_images/leaching.svg";
import deniticon from "../assets/_images/denitrification.svg";
import "./Integra.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import volitlogo from "../assets/_images/VolitTM.svg";
import integralogo from "../assets/_images/IntegraTM.svg";
import nurturelogo from "../assets/_images/Nurture-TM.svg";
import nitrifixhalf from "../assets/_images/nitrifixhalf.svg";
import volicon from "../assets/_images/volatilization.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Integra = ({ navG, setNavG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  const customtweenv = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, y: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, y: 150, duration: 0.3 },
          {
            duration: dur,
            y: 0,
            alpha: 1,
          },
        ],
      }
    );
  };
  const customtweenh = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, x: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, x: 150, duration: 0.1 },
          {
            duration: dur,
            x: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const refintegralogo = useRef(null);
  const refintegratag = useRef(null);
  const refintegraicontag = useRef(null);
  const integramainimg = useRef(null);

  useEffect(() => {
    const refintlogo = refintegralogo.current;
    const refinttag = refintegratag.current;
    const refintitag = refintegraicontag.current;
    customtweenv(refintlogo, ".intmaintag", 0.2, 0.5);
    customtweenv(refinttag, ".intmaintag", 0.4, 0.5);
    customtweenv(refintitag, ".intmaintag", 0.5, 0.4);
    const iimgref = integramainimg.current;
    gsap.fromTo(
      iimgref,
      { x: 900, alpha: 0 },
      {
        duration: 0.7,
        alpha: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".intmainimg",
          ease: "power4.out",
        },
      }
    );
  }, []);

  return (
    <div className="wrapper">
      <div className="integramain">
        <div className="integramainwrapper">
          <div className="intmaintag">
            <img
              ref={refintegralogo}
              className="intlogo"
              src={integralogo}
              alt=""
            />
            <p ref={refintegratag}>
              For the most complete protection, IntegraTM combines the
              protective properties of VoLitTM and NitrifiXTM providing your
              Nitrogen with a triple shield against volatilization, leaching and
              denitrification losses.
            </p>
            <div ref={refintegraicontag}>
              <div className="icontag icontagfirst">
                <img src={volicon} alt="" className="iconmain" />
                <p className="iconmsg">
                  Protection against <br /> volatilization losses
                </p>
              </div>
              <div className="icontag">
                <img src={leachicon} alt="" className="iconmain" />
                <p className="iconmsg">
                  Protection against <br /> leaching losses
                </p>
              </div>
              <div className="icontag">
                <img src={deniticon} alt="" className="iconmain" />
                <p className="iconmsg">
                  Protection against <br /> denitrification losses
                </p>
              </div>
            </div>
          </div>
          <div className="intmainimg">
            <img
              ref={integramainimg}
              className="intimg"
              src={integramain}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="intsection2">
        <div className="intsection2-wrapper">
          <div className="int2msg">
            <div
              // ref={countref}
              className="counters1"
            >
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
              <h2>to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={57}
                          end={80}
                          delay={0}
                          duration={0.6}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="int2prot">
              <h2>Reduce Volatilization losses and reduce your input costs.</h2>
              <p>
                The Urease Inhibitor in Integra has been proven to reduce N
                volatilization losses by up to 53-80%, depending on crop and
                climate conditions.
              </p>
            </div>
          </div>
          <div className="int2msg">
            <div className="counters1">
              {/* <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div> */}
              <h2 className="vcounter2">up to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={1}
                          end={9}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span
                                style={{ width: "100%" }}
                                ref={countUpRef}
                              />
                              %
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="int2prot">
              <h2>Deliver Nitrogen more efficiently to your crop. </h2>
              <p>
                Field evidence shows yield increases of up to 9% can be achieved
                depending on crop and field conditions as a result of treating
                Nitrogen sources with the Urease Inhibitor used to formulate
                Integra.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="intsection2 intsection22">
        <div className="intsection2-wrapper">
          <div className="int2msg">
            <div className="counters1">
              {/* <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div> */}
              <h2 className="vcounter2">up to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={1}
                          end={20}
                          delay={0}
                          duration={0.7}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span
                                style={{ width: "100%" }}
                                ref={countUpRef}
                              />
                              %
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="int2prot">
              <h2>
                Keep your Nitrogen in available form and close to the root zone.
              </h2>
              <p>
                The Nitrification Inhibitor in Integra has been shown to
                effectively reduce the transformation or accumulation of
                Nitrates (NO3) by up to 20%. With Integra your Nitrogen stays in
                the more stable and available form of Ammonium for longer
                periods.
              </p>
            </div>
          </div>
          <div className="int2msg">
            <div
              // ref={countref}
              className="counters1"
            >
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={50}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
              <h2>to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={60}
                          end={90}
                          delay={0}
                          duration={0.6}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="int2prot">
              <h2>
                Reduce your NO2 emissions and your denitrification losses.
              </h2>
              <p>
                The Nitrification Inhibitor in Integra has been proven to reduce
                NO2 emissions and related denitrification losses by up to
                50-90%, depending on crop and climate conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="intsection3">
        <div className="intsection3-wrapper"></div>
      </div>
      <div className="volitsection31">
        <div className="volitsection31-wrapper">
          <h2>
            SURFECT<sup>&trade; </sup>
          </h2>
          <p>
            All of our WeProtect<sup>&trade;</sup> products are optimized and
            powered by SURFECT
            <sup>&trade; </sup> technology.
          </p>
          <Link to="/surfect">
            <button>
              Learn More about SURFECT<sup>&trade;</sup>
            </button>
          </Link>
        </div>
      </div>
      <div className="volitsection4">
        <div className="volitsection4-wrapper">
          <h1>
            WeProtect<sup>&trade;</sup> Portfolio
          </h1>
          <h2>Explore more ways to improve your nutrient use efficiency.</h2>
          <div className="inhibcards">
            <Link to="/volit">
              <div className="inhibcard firstinhibcard intinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibvollogo" src={volitlogo} alt="" />
                </div>
                <p>
                  Volit<sup>&trade;</sup> is WeGrow’s new Urease Inhibitor.{" "}
                  <span className="inhibcardmobile">
                    Volit<sup>&trade;</sup> protects your Ureic Nitrogen from
                    Urease allowing it to incorporate into the soil before it
                    gets hydrolyzed. The result is a more efficient use of your
                    Nitrogen and lower losses due to volatilization.
                  </span>
                </p>
                <div className="inhibicons">
                  <img src={volicon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={volicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Volatilization losses
                    </p>
                  </div> */}
                </div>
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/nitrifix">
              <div className="inhibcard intinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibnfxlogo" src={nitrifixlogo} alt="" />
                </div>
                <p>
                  NitrifiX<sup>&trade;</sup> is WeGrow’s new Nitrification
                  Inhibitor.{" "}
                  <span className="inhibcardmobile">
                    NitrifiX<sup>&trade;</sup> increases the efficiency of your
                    Nitrogen by keeping it in Ammonium form for a longer period
                    so that the crop has more time to absorb it. Less of your
                    Nitrogen is lost to leaching or denitrification.
                  </span>
                </p>
                <div className="inhibicons">
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/nurture">
              <div className="inhibcard lastinhibcard intinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibnurlogo" src={nurturelogo} alt="" />
                </div>
                <p>
                  NURTURE<sup>&trade;</sup> next generation Nitrification
                  Inhibitor
                  <span className="inhibcardmobile">
                    helps your nitrogen stay in Ammonia / Ammonium form for a
                    longer period allowing plants more time to absorb it
                  </span>
                  .
                </p>
                <div className="inhibicons">
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>{" "}
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integra;
