import React from "react";
import Logo from "../assets/_images/logowhite.svg";
import "./Footer.css";
import { Link } from "react-router-dom";
import InstaIcon from "../assets/_images/instaicon.png";
import LinkIcon from "../assets/_images/linkicon.png";
import FBIcon from "../assets/_images/fbicon.png";

const Footer = () => {
  return (
    <div className="wrapper">
      <div className="prefooter">
        <div className="prefooter-wrapper">
          <div className="prefootertag">
            <h1 className="prefooterslogan">
              Grow sustainable. <br /> Grow with us.
            </h1>
            <img className="prefooterlogo" src={Logo} alt="" />
          </div>
          <div className="socialfooter">
            <p>Follow us on </p>
            <img className="socfooticon1" src={InstaIcon} alt="" />
            <img src={LinkIcon} alt="" />
            <img src={FBIcon} alt="" />
          </div>
          <div className="officesfooter">
            <div class="officefoot">
              <h3>Switzerland - HQ</h3>
              <ul>
                <li>Zurcherstrasse 68</li>
                <li>8800 Thalwil</li>
                <li>Switzerland</li>
              </ul>
            </div>
            <div class="officefoot">
              <h3>Americas</h3>
              <ul>
                <li>1000 North Ashley Dr.</li>
                <li>Suite 410</li>
                <li>Tampa FL, 33602</li>
                <li>USA</li>
              </ul>
            </div>
            <div class="officefoot">
              <h3>Brazil</h3>
              <ul>
                <li>Rua Casa do Ator</li>
                <li>No. 1.117, sala 153</li>
                <li>Villa Olimpia</li>
                <li>Sao Paulo</li>
                <li>Brazil</li>
              </ul>
            </div>
            <div class="officefoot">
              <h3>Spain</h3>
              <ul>
                <li>Calle Villalar 4</li>
                <li>28001 Madrid</li>
                <li>Spain</li>
              </ul>
            </div>
            <div class="officefoot">
              <h3>China</h3>
              <ul>
                <li>Beijing IH</li>
                <li>Suite 605-606</li>
                <li>N-9 Jian Guo Men Nei Ave.</li>
                <li>Dongcheng District</li>
                <li>100005 Beijing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-wrapper">
          <p>
            <Link className="footerlink" to={"/privacy"}>
              PRIVACY
            </Link>
            <span className="footnmob">|</span>
            <Link className="footerlink" to={"/disclaimer"}>
              DISCLAIMER
            </Link>
            <span className="footnmob">|</span>
            <Link className="footerlink" to={"/disclaimer"}>
              SITE MAP
            </Link>
            <span className="footnmob">|</span> WeGrow &#169; Copyright 2021
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
