import React, { useEffect } from "react";
import "./Straights.css";
import Logoblacksimple from "../assets/_images/logo-white-simple-shadow.png";
import Tmapbag from "../assets/_images/bag1.png";

const Straights = ({ setNavG, navG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);
  return (
    <>
      {/* <Nav navG={navG} setNavG={setNavG} /> */}
      <div className="straightprod">
        {/* <div className="straightscontact"> */}
        <button className="straightscontact">Contact An Expert</button>
        {/* </div> */}
        <img src={Tmapbag} className="straightsbag" alt="" />
        <div className="straightswesolve">
          {/* <img src={Logoblacksimple} className="straightslogo" alt="" /> */}
          <h1>
            WeSolve<sup>&reg;</sup>
          </h1>
        </div>
        <div className="straightsprod-wrapper">
          <div className="straightsleft">
            <h1>Technical Monoammonium Phosphate</h1>
            <h2>TMAP</h2>
            <h3>12-61-0</h3>
            <div className="straightsdescription">
              <h4>Description:</h4>
              <ul>
                <li>Water soluble</li>
                <li>
                  Excelent high-concentration source of water soluble Phosphorus
                </li>
                <li>Suitable for fertigation</li>
              </ul>
            </div>
          </div>
          <div className="straightsright"></div>
        </div>
      </div>
    </>
  );
};

export default Straights;
