import ReactGA from "react-ga";

// import { useCookies } from "react-cookie";
// const [cookies, setCookies] = useCookies();
// console.log(cookies["WGCookieConsent"]);

const TRACKING_ID = "UA-210079339-1";

function init(check) {
  // console.log("Almost there");
  // console.log(check);
  // Enable debug mode on the local development environment
  //   const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  //   ReactGA.initialize(TRACKING_ID, { debug: isDev })
  if (check === "true") {
    ReactGA.initialize(TRACKING_ID);
    // console.log("we made it");
  }
  // ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
