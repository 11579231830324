import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav2 from "./components/Nav2";
// import Home from "./components/Home";
import RoutesP from "./RoutesP";
import Footer from "./components/Footer";
// import { useCookies } from "react-cookie";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  // const [cookies, setCookies] = useCookies("WGCookieConsent");
  // console.log(cookies["WGCookieConsent"]);
  const [navG, setNavG] = useState(false);
  return (
    <div>
      <Router>
        <ScrollToTop />
        <header>
          <Nav2 navG={navG} setNavG={setNavG} />
        </header>
        {/* <Routes>
          <Route exact path="/" element={<Home />}></Route>
        </Routes> */}
        <RoutesP setNavG={setNavG} navG={navG} />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
