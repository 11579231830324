import React, { useEffect } from "react";
import "./Privacy.css";

const Privacy = ({ setNavG, navG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);

  return (
    <div className="privacywrapper">
      <div className="privacycontent">
        <h2>Privacy Notice</h2>
        <p>
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are
          text files placed on your computer, to help the website analyze how
          users use the site. The information generated by the cookie about your
          use of the website (including your IP address) will be transmitted to
          and stored by Google on servers in the United States.
        </p>

        <p>
          Google will use this information for the purpose of evaluating your
          use of the website, compiling reports on website activity for website
          operators and providing other services relating to website activity
          and internet usage. Google may also transfer this information to third
          parties where required to do so by law, or where such third parties
          process the information on Google’s behalf. Google will not associate
          your IP address with any other data held by Google.
        </p>

        <p>
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser, however please note that if you do this you
          may not be able to use the full functionality of this website. By
          using this website, you consent to the processing of data about you by
          Google in the manner and for the purposes set out above.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
