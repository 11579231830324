import React, { useEffect, useRef, useState } from "react";
import "./ContactUsModal.css";
import OutsideAlerter from "./OutsideAlerter";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUsModal = ({ showcontactus, setShowcontactus }) => {
  const showcontactmodal = showcontactus
    ? "modal display-block"
    : "modal display-none";

  const [showSubmitButtonC, setShowSubmitButtonC] = useState(false);
  function onChange(value) {
    if (value) {
      setShowSubmitButtonC(true);
    }
    // console.log("Captcha value:", value);
  }
  return (
    <div className={showcontactmodal}>
      <OutsideAlerter setShowcontactus={setShowcontactus}>
        <div className="modal-main">
          <h1>Connect with a WeGrow Expert!</h1>
          <p>
            We are glad you want to get in touch with one of our experts. Please
            fill the following form and we will be happy to get in touch with
            you to facilitate an introduction to our products and services.
            Thank you!
          </p>
          <form>
            <div className="form-group">
              <label htmlFor="">
                Name<sup>*</sup>:
              </label>
              <input />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Email<sup>*</sup>:
              </label>
              <input />
            </div>
            <div className="form-group">
              <label htmlFor="">Location:</label>
              <input />
            </div>
            <div className="form-group">
              <label htmlFor="">Message:</label>
              <textarea placeholder="Hi I am interested in..." />
            </div>

            {/* <ReCAPTCHA
              style={{ width: "300px", margin: "0 auto", marginTop: "1.5rem" }}
              //     sitekey="6LegMUofAAAAAL7dfvWNR-kHpbwsQEY5GPRz1glG
              //   "
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={onChange}
            /> */}
            <button
            // onClick={(e) => {
            //   setShowcontactus(false);
            // }}
            // className={showSubmitButtonC ? "display-block" : "display-none"}
            >
              Submit
            </button>
          </form>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default ContactUsModal;
