import React, { useEffect, useRef } from "react";
import volitlogo from "../assets/_images/VolitTM.svg";
import volitmain from "../assets/_images/volitmain.jpg";
import nitrifixlogo from "../assets/_images/NitrifixTM.svg";
import integralogo from "../assets/_images/IntegraTM.svg";
import nurturelogo from "../assets/_images/Nurture-TM.svg";
import "./Volit.css";
import CountUp from "react-countup";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VisibilitySensor from "react-visibility-sensor";
import volithalf from "../assets/_images/volithalf.svg";
import volicon from "../assets/_images/volatilization.svg";
import leachicon from "../assets/_images/leaching.svg";
import deniticon from "../assets/_images/denitrification.svg";
import { Link } from "react-router-dom";

const Volit = ({ navG, setNavG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  const customtweenv = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, y: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, y: 150, duration: 0.3 },
          {
            duration: dur,
            y: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const customtweenh = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, x: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, x: 150, duration: 0.1 },
          {
            duration: dur,
            x: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const countref = useRef(null);
  const volitmainimg = useRef(null);
  const refvolitlogo = useRef(null);
  const refvolittag = useRef(null);
  const refvoliticontag = useRef(null);

  const volmsg1 = useRef(null);
  const volmsg2 = useRef(null);
  const volmsg3 = useRef(null);
  const volmsg4 = useRef(null);

  useEffect(() => {
    const cref = countref.current;
    customtweenv(cref, ".volitsection2", 0.2, 0.6);
    const refvollogo = refvolitlogo.current;
    const refvoltag = refvolittag.current;
    const refvolitag = refvoliticontag.current;
    customtweenv(refvollogo, ".vmaintag", 0.2, 0.5);
    customtweenv(refvoltag, ".vmaintag", 0.4, 0.5);
    customtweenv(refvolitag, ".vmaintag", 0.5, 0.4);
    const vimgref = volitmainimg.current;
    gsap.fromTo(
      vimgref,
      { x: 900, alpha: 0 },
      {
        duration: 0.7,
        alpha: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".vmainimg",
          ease: "power4.out",
        },
      }
    );
    const volsmg1c = volmsg1.current;
    const volsmg2c = volmsg2.current;
    const volsmg3c = volmsg3.current;
    const volsmg4c = volmsg4.current;
    customtweenh(volsmg1c, ".v3item1", 0.7, 0.2);
    customtweenh(volsmg2c, ".v3item1", 0.9, 0.2);
    customtweenh(volsmg3c, ".v3item1", 1.1, 0.2);
    customtweenh(volsmg4c, ".v3item1", 1.3, 0.2);
  }, []);
  return (
    <div className="wrapper">
      <div className="volitmain">
        <div className="volitmainwrapper">
          <div className="vmaintag">
            <img ref={refvolitlogo} className="vlogo" src={volitlogo} alt="" />
            <p ref={refvolittag}>
              Volit<sup>&trade;</sup> is WeGrow’s new Urease Inhibitor. Volit
              <sup>&trade;</sup>
              protects your Ureic Nitrogen from Urease allowing it to
              incorporate into the soil before it gets hydrolyzed. The result is
              a more efficient use of your Nitrogen and lower losses due to
              volatilization.
            </p>
            <div ref={refvoliticontag} className="icontag icontagfirst">
              <img src={volicon} alt="" className="iconmain" />
              <p className="iconmsg">
                Protection against <br /> volatilization losses
              </p>
            </div>
          </div>
          <div className="vmainimg">
            <img ref={volitmainimg} className="vimg" src={volitmain} alt="" />
          </div>
        </div>
      </div>
      <div className="volitsection2">
        <div className="volitsection2-wrapper">
          <div className="vs2msg">
            <div
              // ref={countref}
              className="counters1"
            >
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
              <h2>to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={57}
                          end={80}
                          delay={0}
                          duration={0.6}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="vs2prot">
              <h2>Reduce Volatilization losses and reduce your input costs.</h2>
              <p>
                The active ingredient in VoLit<sup>&trade;</sup> has been proven
                to reduce N volatilization losses by up to 53-80%, depending on
                crop and climate conditions.
              </p>
            </div>
          </div>
          <div className="vs2msg">
            <div className="counters1">
              {/* <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div> */}
              <h2 className="vcounter2">up to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={1}
                          end={9}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span
                                style={{ width: "100%" }}
                                ref={countUpRef}
                              />
                              %
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="vs2prot">
              <h2>Deliver Nitrogen more efficiently to your crop. </h2>
              <p>
                Field evidence shows yield increases of up to 9% can be achieved
                depending on crop and field conditions as a result of treating
                Nitrogen sources with VoLit.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="volitsection3">
        <img src={volithalf} alt="" />
        <div className="volitsection3-wrapper">
          <div
            ref={volmsg1}
            className="v3item1 v3itemshort"
            // style={{ marginLeft: "10%" }}
          >
            <div className="v3item1line"></div>
            <div className="v3item1text">
              <h2>Ease of Use.</h2>
              <p>
                VoLit<sup>&trade;</sup> is powered by SURFECT<sup>&trade; </sup>
                technology providing it with optimal characteristics for your
                spraying or mixing process. SURFECT<sup>&trade; </sup> will
                ensure you achieve a homogenous spraying and/or stable liquid
                blend when you spray or mix VoLit<sup>&trade;</sup> with your
                Nitrogen source of choice. We offer personalized technical
                support.
              </p>
            </div>
          </div>
          <div
            ref={volmsg2}
            className="v3item1 v3itemlong"
            // style={{ marginLeft: "23%" }}
          >
            <div className="v3item1line"></div>
            <div className="v3item1text">
              <h2>
                Flexible Nitrogen fertilization and efficient use in NoTillage
                Systems.
              </h2>
              <p>
                Using VoLit<sup>&trade;</sup> gives you flexibility for
                application timing and allows for efficient use of Urea treated
                with VoLit<sup>&trade;</sup> in no-tillage or reduced-tillage
                systems.
              </p>
            </div>
          </div>
          <div
            ref={volmsg3}
            className="v3item1 v3itemlong"
            // style={{ marginLeft: "23%" }}
          >
            <div className="v3item1line"></div>
            <div className="v3item1text">
              <h2>Mitigate pH variations around the granule.</h2>
              <p>
                Urea hidrolization results in an increase in pH, which tilts the
                reaction balance towards more Amonia production and more
                volatilization. When treated with Volit<sup>&trade;</sup>, urea
                granules get to disolve and diffuse in the soil solution before
                hidrolization occurs, thus mitigating the otherwise extreme pH
                changes around the Urea granule and reducing the propensity for
                volatilization.
              </p>
            </div>
          </div>
          <div
            ref={volmsg4}
            className="v3item1 v3itemshort"
            // style={{ marginLeft: "10%" }}
          >
            <div className="v3item1line"></div>
            <div className="v3item1text">
              <h2>Protect the Environment.</h2>
              <p>
                The more efficient use of Nitrogen not only benefits your bottom
                line economics but in turn results in an overall lower carbon
                footprint. The components used to elaborate VoLit
                <sup>&trade;</sup> are friendly to the environment and do not
                alter soil fertility.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="volitsection31">
        <div className="volitsection31-wrapper">
          <h2>
            SURFECT<sup>&trade; </sup>
          </h2>
          <p>
            All of our WeProtect<sup>&trade;</sup> products are optimized and
            powered by SURFECT
            <sup>&trade; </sup> technology.
          </p>
          <Link to="/surfect">
            <button>
              Learn More about SURFECT<sup>&trade;</sup>
            </button>
          </Link>
        </div>
      </div>
      <div className="volitsection4">
        <div className="volitsection4-wrapper">
          <h1>
            WeProtect<sup>&trade;</sup> Portfolio
          </h1>
          <h2>Explore more ways to improve your nutrient use efficiency.</h2>
          <div className="inhibcards">
            <Link to="/nitrifix">
              <div className="inhibcard firstinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibnfxlogo" src={nitrifixlogo} alt="" />
                </div>
                <p>
                  NitrifiX<sup>&trade;</sup> is WeGrow’s new Nitrification
                  Inhibitor.{" "}
                  <span className="inhibcardmobile">
                    NitrifiX<sup>&trade;</sup> increases the efficiency of your
                    Nitrogen by keeping it in Ammonium form for a longer period
                    so that the crop has more time to absorb it. Less of your
                    Nitrogen is lost to leaching or denitrification.
                  </span>
                </p>
                <div className="inhibicons">
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/integra">
              <div className="inhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibintlogo" src={integralogo} alt="" />
                </div>
                <p>
                  <span className="inhibcardmobile">
                    For the most complete protection,{" "}
                  </span>
                  Integra<sup>&trade;</sup> combines the protective properties
                  of VoLit<sup>&trade;</sup> and NitrifiX<sup>&trade;</sup>{" "}
                  <span className="inhibcardmobile">
                    providing your Nitrogen with a triple shield against
                    volatilization, leaching and denitrification losses
                  </span>
                  .
                </p>
                <div className="inhibicons">
                  <img src={volicon} className="inhibicon" alt="" />
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={volicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Volatilization losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>{" "}
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/nurture">
              <div className="inhibcard lastinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibnurlogo" src={nurturelogo} alt="" />
                </div>
                <p>
                  NURTURE<sup>&trade;</sup> next generation Nitrification
                  Inhibitor
                  <span className="inhibcardmobile">
                    helps your nitrogen stay in Ammonia / Ammonium form for a
                    longer period allowing plants more time to absorb it
                  </span>
                  .
                </p>
                <div className="inhibicons">
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>{" "}
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Volit;
