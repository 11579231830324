import React, { useEffect, useRef } from "react";
import nitrifixlogo from "../assets/_images/NitrifixTM.svg";
import nitrifixmain from "../assets/_images/nitrifixmain.svg";
import leachicon from "../assets/_images/leaching.svg";
import deniticon from "../assets/_images/denitrification.svg";
import "./Nitrifix.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import volitlogo from "../assets/_images/VolitTM.svg";
import integralogo from "../assets/_images/IntegraTM.svg";
import nurturelogo from "../assets/_images/Nurture-TM.svg";
import nitrifixhalf from "../assets/_images/nitrifixhalf.svg";
import volicon from "../assets/_images/volatilization.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Nitrifix = ({ navG, setNavG }) => {
  useEffect(() => {
    setNavG(true);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  const customtweenv = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, y: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, y: 150, duration: 0.3 },
          {
            duration: dur,
            y: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const customtweenh = (elem, trig, delay, dur = 0.3, strt = "") => {
    gsap.fromTo(
      elem,
      {},
      {
        scrollTrigger: {
          trigger: trig,
          start: strt,
        },
        keyframes: [
          { alpha: 0, x: 300, duration: 0.0000001 },
          { delay: delay, alpha: 0.2, x: 150, duration: 0.1 },
          {
            duration: dur,
            x: 0,
            alpha: 1,
          },
        ],
      }
    );
  };

  const nfxmainimg = useRef(null);
  const refnfxlogo = useRef(null);
  const refnfxtag = useRef(null);
  const refnfxicontag = useRef(null);
  const refnfxicontag2 = useRef(null);

  const nfxmsg1 = useRef(null);
  const nfxmsg2 = useRef(null);
  const nfxmsg3 = useRef(null);

  useEffect(() => {
    const refnflogo = refnfxlogo.current;
    const refnftag = refnfxtag.current;
    const refnfitag = refnfxicontag.current;
    const refnfitag2 = refnfxicontag2.current;
    customtweenv(refnflogo, ".nfxmaintag", 0.2, 0.5);
    customtweenv(refnftag, ".nfxmaintag", 0.4, 0.5);
    customtweenv(refnfitag, ".nfxmaintag", 0.5, 0.4);
    customtweenv(refnfitag2, ".nfxmaintag", 0.5, 0.4);
    const nfximgref = nfxmainimg.current;
    gsap.fromTo(
      nfximgref,
      { x: 900, alpha: 0 },
      {
        duration: 0.7,
        alpha: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".nfxmainimg",
          ease: "power4.out",
        },
      }
    );
    const nfxsmg1c = nfxmsg1.current;
    const nfxsmg2c = nfxmsg2.current;
    const nfxsmg3c = nfxmsg3.current;
    customtweenh(nfxsmg1c, ".nfx3item1", 0.7, 0.2);
    customtweenh(nfxsmg2c, ".nfx3item1", 0.9, 0.2);
    customtweenh(nfxsmg3c, ".nfx3item1", 1.1, 0.2);
  }, []);

  return (
    <div className="wrapper">
      <div className="nitrifixmain">
        <div className="nitrifixmainwrapper">
          <div className="nfxmaintag">
            <img
              ref={refnfxlogo}
              className="nfxlogo"
              src={nitrifixlogo}
              alt=""
            />
            <p ref={refnfxtag}>
              NitrifiX<sup>&trade;</sup> is WeGrow's new Nitrification
              Inhibitor. NitrifiX<sup>&trade; </sup>
              increases the efficiency of your Nitrogen by keeping it in
              Ammonium form for a longer period so that the crop has more time
              to absorb it. Less of your Nitrogen is lost to leaching or
              denitrification.
            </p>
            <div ref={refnfxicontag} className="icontag icontagfirst">
              <img src={leachicon} alt="" className="iconmain" />
              <p className="iconmsg">
                Protection against <br /> leaching losses
              </p>
            </div>
            <div ref={refnfxicontag2} className="icontag">
              <img src={deniticon} alt="" className="iconmain" />
              <p className="iconmsg">
                Protection against <br /> denitrification losses
              </p>
            </div>
          </div>
          <div className="nfxmainimg">
            <img
              ref={nfxmainimg}
              className="nfximg"
              src={nitrifixmain}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="nfxsection2">
        <div className="nfxsection2-wrapper">
          <div className="nfx2msg">
            <div
              // ref={countref}
              className="counters1"
            >
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={50}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
              <h2>to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={60}
                          end={90}
                          delay={0}
                          duration={0.6}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="nfx2prot">
              <h2>
                Reduce your NO2 emissions and your denitrification losses.
              </h2>
              <p>
                The active ingredient in NitrifiX<sup>&trade;</sup> has been
                proven to reduce NO2 emissions and related denitrification
                losses by up to 50-90%, depending on crop and climate
                conditions.
              </p>
            </div>
          </div>
          <div className="nfx2msg">
            <div className="counters1">
              {/* <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 280 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={20}
                          end={53}
                          delay={0}
                          duration={1}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />%
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div> */}
              <h2 className="vcounter2">up to</h2>
              <div className="counter1">
                <VisibilitySensor partialVisibility offset={{ bottom: 250 }}>
                  {({ isVisible }) => (
                    <div style={{ height: 100 }}>
                      {isVisible ? (
                        <CountUp
                          start={1}
                          end={20}
                          delay={0}
                          duration={0.7}
                          // useEasing=true
                          // easingFn="easeInOutCubic"
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span
                                style={{ width: "100%" }}
                                ref={countUpRef}
                              />
                              %
                            </div>
                          )}
                        </CountUp>
                      ) : null}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="nfx2prot">
              <h2>
                Keep your Nitrogen in available form and close to the root zone.
              </h2>
              <p>
                The Nitrification Inhibitor in NitrifiX<sup>&trade;</sup> has
                been shown to effectively reduce the transformation or
                accumulation of Nitrates (NO3) by up to 20%. With NitrifiX
                <sup>&trade;</sup> your Nitrogen stays in the more stable and
                available form of Ammonium for longer periods.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="nfxsection3">
        <img src={nitrifixhalf} alt="" />
        <div className="nfxsection3-wrapper">
          <div
            ref={nfxmsg1}
            className="nfx3item1 nfxitemshort"
            // style={{ marginRight: "10%" }}
          >
            <div className="nfx3item1text">
              <h2>Ease of Use.</h2>
              <p>
                NitrifiX<sup>&trade;</sup> is powered by SURFECT
                <sup>&trade; </sup>
                technology providing it with optimal characteristics for your
                spraying or mixing process. SURFECT<sup>&trade; </sup> will
                ensure you achieve a homogenous spraying and/or stable liquid
                blend when you spray or mix NitrifiX<sup>&trade;</sup> with your
                Nitrogen source of choice. We offer personalized technical
                support.
              </p>
            </div>
            <div className="nfx3item1line"></div>
          </div>
          <div
            ref={nfxmsg2}
            className="nfx3item1 nfxitemlong"
            // style={{ marginRight: "23%" }}
          >
            <div className="nfx3item1text">
              <h2>Versatile protection of your Ammoniacal Nitrogen.</h2>
              <p>
                NitrifiX<sup>&trade;</sup> is designed to be effectively used
                with different types of ureic and ammoniacal fertilizers like
                AN, AS, UAN and Urea.
              </p>
            </div>
            <div className="nfx3item1line"></div>
          </div>
          <div
            ref={nfxmsg3}
            className="nfx3item1 nfxitemshort"
            // style={{ marginRight: "10%" }}
          >
            <div className="nfx3item1text">
              <h2>Be friendly to your soil.</h2>
              <p>
                NitrifiX<sup>&trade;</sup> is a technology that is friendly to
                the environment and does not alter the soil fertility. The
                active ingredient in NitrifiX<sup>&trade;</sup> temporarily
                inhibits the AMO enzyme in some Nitrifying Bacteria creating a
                temporal bacteriostatic effect which allows your crop more time
                to abosrb Nitrogen in Ammoniacal form.
              </p>
            </div>
            <div className="nfx3item1line"></div>
          </div>
        </div>
      </div>
      <div className="volitsection31">
        <div className="volitsection31-wrapper">
          <h2>
            SURFECT<sup>&trade; </sup>
          </h2>
          <p>
            All of our WeProtect<sup>&trade;</sup> products are optimized and
            powered by SURFECT
            <sup>&trade; </sup> technology.
          </p>
          <Link to="/surfect">
            <button>
              Learn More about SURFECT<sup>&trade;</sup>
            </button>
          </Link>
        </div>
      </div>
      <div className="volitsection4">
        <div className="volitsection4-wrapper">
          <h1>
            WeProtect<sup>&trade;</sup> Portfolio
          </h1>
          <h2>Explore more ways to improve your nutrient use efficiency.</h2>
          <div className="inhibcards">
            <Link to="/volit">
              <div className="inhibcard nfxinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibvollogo" src={volitlogo} alt="" />
                </div>
                <p>
                  Volit<sup>&trade;</sup> is WeGrow’s new Urease Inhibitor.{" "}
                  <span className="inhibcardmobile">
                    Volit<sup>&trade;</sup> protects your Ureic Nitrogen from
                    Urease allowing it to incorporate into the soil before it
                    gets hydrolyzed. The result is a more efficient use of your
                    Nitrogen and lower losses due to volatilization.
                  </span>
                </p>
                <div className="inhibicons">
                  <img src={volicon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={volicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Volatilization losses
                    </p>
                  </div> */}
                </div>
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/integra">
              <div className="inhibcard nfxinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibintlogo" src={integralogo} alt="" />
                </div>
                <p>
                  <span className="inhibcardmobile">
                    For the most complete protection,{" "}
                  </span>
                  Integra<sup>&trade;</sup> combines the protective properties
                  of VoLit<sup>&trade;</sup> and NitrifiX<sup>&trade;</sup>{" "}
                  <span className="inhibcardmobile">
                    providing your Nitrogen with a triple shield against
                    volatilization, leaching and denitrification losses
                  </span>
                  .
                </p>
                <div className="inhibicons">
                  <img src={volicon} className="inhibicon" alt="" />
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={volicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Volatilization losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>{" "}
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
            <Link to="/nurture">
              <div className="inhibcard nfxinhibcard lastinhibcard">
                <div className="inhibcardtitle">
                  <img className="inhibnurlogo" src={nurturelogo} alt="" />
                </div>
                <p>
                  NURTURE<sup>&trade;</sup> next generation Nitrification
                  Inhibitor
                  <span className="inhibcardmobile">
                    helps your nitrogen stay in Ammonia / Ammonium form for a
                    longer period allowing plants more time to absorb it
                  </span>
                  .
                </p>
                <div className="inhibicons">
                  <img src={leachicon} className="inhibicon" alt="" />
                  <img src={deniticon} className="inhibicon" alt="" />
                  {/* <div className="inhibicongroup">
                    <img src={leachicon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Leaching losses
                    </p>
                  </div>
                  <div className="inhibicongroup">
                    <img src={deniticon} alt="" className="inhibicon" />
                    <p className="inhibicontext">
                      Protection against <br /> Denitrification losses
                    </p>
                  </div> */}
                </div>{" "}
                <button className="inhibcardbutton">Learn More</button>
              </div>
            </Link>
          </div>
          {/* <div className="inhiblegend">
            <div className="inhibicongroup">
              <img src={volicon} alt="" className="inhibicon" />
              <p className="inhibicontext">
                Protection against <br /> Volatilization losses
              </p>
            </div>
            <div className="inhibicongroup">
              <img src={leachicon} alt="" className="inhibicon" />
              <p className="inhibicontext">
                Protection against <br /> Leaching losses
              </p>
            </div>
            <div className="inhibicongroup">
              <img src={deniticon} alt="" className="inhibicon" />
              <p className="inhibicontext">
                Protection against <br /> Denitrification losses
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Nitrifix;
